import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import eye from "../../../assets/ExecutionMentor/eye.svg";
import { AuthContext } from "../../../contexts/AuthProvider";
import Layout from "../Layout";
import AssignmentUpNev from "./AssignmentUpNev";
import Loading from "../../Shared/Loading/Loading";
import toast from "react-hot-toast";

const MentorAssignments = () => {
  const { userInfo } = useContext(AuthContext);

  const [tableWidth, setTableWidth] = useState("100%");
  const [courses, setCourses] = useState([]);
  const [batchesData, setBatchesData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedBatch, setSelectedBatch] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [allMyStudents, setAllMyStudents] = useState([]);
  const [filteredAssignments, setFilteredAssignment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assignments, setAssignments] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState();
  const [selectedMentor, setSelectedMentor] = useState({});
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [editOrAssignMentor, setEditOrAssignMentor] = useState({});
  const [selectedMentorsForEditOrAssign, setSelectedMentorsForEditOrAssign] =
    useState([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPendingEvaluation, setTotalPendingEvaluation] = useState();
  // const [limit] = useState(50); // Fixed to 25 items per page
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    if (userInfo) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_SERVERLESS_API}/api/v1/language/getItemDetailsByOrganizationAndName/assignments/organizationsId/${userInfo?.organizationId}`
        )
        .then((response) => {
          setItemDetails(response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  }, [userInfo]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/organizationId/${userInfo.organizationId}`
  //     )
  //     .then((response) => {
  //       if (userInfo?.role === "admin") {
  //         setAssignments(response?.data?.slice().reverse() || []);
  //         setFilteredAssignment(response?.data?.slice().reverse() || []);
  //       } else {
  //         setAssignments(
  //           response?.data
  //             ?.slice()
  //             .reverse()
  //             ?.filter((item) =>
  //               item?.mentors?.find(
  //                 (mentor) => mentor?.mentorId === userInfo?._id
  //               )
  //             )
  //         );
  //         setFilteredAssignment(
  //           response?.data
  //             ?.slice()
  //             .reverse()
  //             ?.filter((item) =>
  //               item?.mentors?.find(
  //                 (mentor) => mentor?.mentorId === userInfo?._id
  //               )
  //             )
  //         );
  //       }
  //       //console.log(response?.data[0]);
  //       const ass = response?.data;
  //       setIsLoading(false);
  //       // setPendingEvaluations(ass.length)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     });
  //   setIsLoading(false);
  // }, [userInfo, count]);



  // useEffect(() => {
  //   setIsDataLoad(true)
  //   axios
  //     .get(
  //       `http://localhost:5000/api/v1/assignmentSubmissions/organizationId/${userInfo.organizationId}?page=${currentPage}&limit=${limit}&courseId=${selectedCourse?._id || ""}&batch=${selectedBatch?._id || ""}&status=${selectedStatus || ""
  //       }`
  //     )

  //     .then((response) => {
  //       if (userInfo?.role === "admin") {
  //         setAssignments(response?.data?.submissions.slice() || []);
  //         setFilteredAssignment(response?.data?.submissions.slice() || []);
  //         setTotalPages(response?.data?.pagination.totalPages);
  //         setTotalPendingEvaluation(response?.data?.totalPending)
  //         setIsDataLoad(false)
  //       } else {
  //         setAssignments(
  //           response?.data
  //             ?.slice()
  //             // .reverse()
  //             ?.filter((item) =>
  //               item?.mentors?.find(
  //                 (mentor) => mentor?.mentorId === userInfo?._id
  //               )
  //             )
  //         );
  //         setFilteredAssignment(
  //           response?.data
  //             ?.slice()
  //             // .reverse()
  //             ?.filter((item) =>
  //               item?.mentors?.find(
  //                 (mentor) => mentor?.mentorId === userInfo?._id
  //               )
  //             )
  //         );
  //         setIsDataLoad(false)
  //       }
  //       //console.log(response?.data[0]);
  //       const ass = response?.data;
  //       setIsLoading(false);
  //       // setPendingEvaluations(ass.length)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //       setIsDataLoad(false)
  //     });
  //   setIsLoading(false);
  // }, [userInfo, count, currentPage, limit, selectedCourse?._id, selectedBatch?._id, selectedCourse?.courseFullName, selectedStatus]);

  // const fetchAssignments = () => {
  //   setIsDataLoad(true);
  //   setIsLoading(true);

  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/paginate/organizationId/${userInfo.organizationId}?page=${currentPage}&limit=${limit}&courseId=${selectedCourse?._id || ""}&batch=${selectedBatch?._id || ""}&status=${selectedStatus || ""}`
  //     )
  //     .then((response) => {
  //       if (userInfo?.role === "admin") {
  //         setAssignments(response?.data?.submissions.slice() || []);
  //         setFilteredAssignment(response?.data?.submissions.slice() || []);
  //         setTotalPages(response?.data?.pagination.totalPages);
  //         setTotalPendingEvaluation(response?.data?.totalPending);
  //       } else {
  //         const filteredSubmissions = response?.data
  //           ?.slice()
  //           ?.filter((item) =>
  //             item?.mentors?.find(
  //               (mentor) => mentor?.mentorId === userInfo?._id
  //             )
  //           );

  //         setAssignments(filteredSubmissions);
  //         setFilteredAssignment(filteredSubmissions);
  //       }

  //       setIsDataLoad(false);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsDataLoad(false);
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   fetchAssignments();
  // }, [
  //   userInfo,
  //   count,
  //   currentPage,
  //   limit,
  //   selectedCourse?._id,
  //   selectedBatch?._id,
  //   selectedCourse?.courseFullName,
  //   selectedStatus
  // ]);




  // Pagination change handler

  const fetchAssignments = () => {
    setIsDataLoad(true);
    setIsLoading(true);
    if (userInfo?.role === "admin") {
      axios
        .get(
          `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/paginate/organizationId/${userInfo.organizationId}?page=${currentPage}&limit=${limit}&courseId=${selectedCourse?._id || ""}&batch=${selectedBatch?._id || ""}&status=${selectedStatus || ""}`
        )
        .then((response) => {

          setAssignments(response?.data?.submissions.slice() || []);
          setFilteredAssignment(response?.data?.submissions.slice() || []);
          setTotalPages(response?.data?.pagination.totalPages);
          setTotalPendingEvaluation(response?.data?.totalPending);


          setIsDataLoad(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoad(false);
          setIsLoading(false);
        });

    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/paginate/organizationId/${userInfo.organizationId}?page=${currentPage}&limit=${limit}&courseId=${selectedCourse?._id || ""}&batch=${selectedBatch?._id || ""}&status=${selectedStatus || ""}&mentorId=${userInfo?._id}`
        )
        .then((response) => {

          setAssignments(response?.data?.submissions.slice() || []);
          setFilteredAssignment(response?.data?.submissions.slice() || []);
          setTotalPages(response?.data?.pagination.totalPages);
          setTotalPendingEvaluation(response?.data?.totalPending);


          setIsDataLoad(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsDataLoad(false);
          setIsLoading(false);
        });
    }

    axios
      .get(
        `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/paginate/organizationId/${userInfo.organizationId}?page=${currentPage}&limit=${limit}&courseId=${selectedCourse?._id || ""}&batch=${selectedBatch?._id || ""}&status=${selectedStatus || ""}`
      )
      .then((response) => {
        if (userInfo?.role === "admin") {
          setAssignments(response?.data?.submissions.slice() || []);
          setFilteredAssignment(response?.data?.submissions.slice() || []);
          setTotalPages(response?.data?.pagination.totalPages);
          setTotalPendingEvaluation(response?.data?.totalPending);
        } else {
          const filteredSubmissions = response?.data
            ?.slice()
            ?.filter((item) =>
              item?.mentors?.find(
                (mentor) => mentor?.mentorId === userInfo?._id
              )
            );

          setAssignments(filteredSubmissions);
          setFilteredAssignment(filteredSubmissions);
        }

        setIsDataLoad(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsDataLoad(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAssignments();
  }, [
    userInfo,
    count,
    currentPage,
    limit,
    selectedCourse?._id,
    selectedBatch?._id,
    selectedCourse?.courseFullName,
    selectedStatus
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }
  // Helper function for pagination buttons with ellipses
  const getPagination = () => {
    const delta = 2;
    const range = [];
    const left = Math.max(2, currentPage - delta);
    const right = Math.min(totalPages - 1, currentPage + delta);

    for (let i = left; i <= right; i++) {
      range.push(i);
    }

    if (left > 2) {
      range.unshift("...");
    }
    if (right < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1);
    if (totalPages > 1) {
      range.push(totalPages);
    }

    return range;
  };

  // console.log(isDataLoad)
  const filteredData = assignments.filter((item) => !item?.submitter.result);

  useEffect(() => {
    // Calculate the desired width (e.g., 200px less than the screen width)
    const screenWidth = window.innerWidth;
    const desiredWidth = screenWidth - 350;

    // Set the table width as a string
    setTableWidth(`${desiredWidth}px`);

    // Update the width if the window is resized
    const handleResize = () => {
      const updatedWidth = window.innerWidth - 350;
      setTableWidth(`${updatedWidth}px`);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVERLESS_API}/api/v1/courses/organizationId/${userInfo?.organizationId}`
  //     )
  //     .then((response) => {
  //       setCourses(response?.data || []);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     });
  // }, [userInfo]);
  useEffect(() => {
    setIsLoading(true); // Set loading to true when the request starts
    axios
      .get(
        `${process.env.REACT_APP_SERVERLESS_API}/api/v1/courses/organizationId/${userInfo?.organizationId}`
      )
      .then((response) => {
        // Ensure response data is an array
        setCourses(Array.isArray(response?.data) ? response.data : []);
        setIsLoading(false); // Set loading to false after successful request
      })
      .catch((error) => {
        console.error(error);
        setCourses([]); // Set an empty array in case of error
        setIsLoading(false); // Set loading to false after error
      });
  }, [userInfo]);


  useEffect(() => {
    if (selectedCourse?._id)
      axios
        .get(
          `${process.env.REACT_APP_SERVERLESS_API}/api/v1/batches/courseId/${selectedCourse?._id}`
        )
        .then((response) => {
          setBatchesData(response?.data || []);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
  }, [selectedCourse]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVERLESS_API}/api/v1/users/students/${userInfo?.organizationId}`
  //     )
  //     .then((response) => {
  //       setAllMyStudents(response?.data || []);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     });
  // }, [userInfo]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVERLESS_API}/api/v1/users/mentors/organizationId/${userInfo?.organizationId}/role/execution mentor`
      )
      .then((response) => {
        setMentors(Array.isArray(response?.data) ? response.data : []); // Ensure it's an array
      })
      .catch((error) => console.error(error));
  }, [userInfo]);


  const applyFilters = () => {
    let filtered = assignments;
    // console.log(selectedStatus);

    // Apply course filter
    if (selectedCourse?._id) {
      filtered = filtered?.filter(
        (submission) => submission?.courseId === selectedCourse?._id
      );
    }
    //console.log(filtered);

    // Apply batch filter
    if (selectedBatch?._id) {
      filtered = filtered?.filter(
        (submission) => submission?.batchId === selectedBatch?._id
      );
    }

    if (selectedStatus === "Submitted") {
      filtered = filtered?.filter(
        (assignment) => assignment?.submitter?.result
      );
    }
    if (selectedStatus === "Pending") {
      filtered = filtered?.filter(
        (assignment) => !assignment?.submitter?.result
      );
    }
    setFilteredAssignment(filtered);
    // if (matchingAssignments) {
    // } else {
    //   console.log("none");
    //   setFilteredAssignment(assignments);
    // }
  };

  function formatDateTime(dateTimeString) {
    const dateObject = new Date(dateTimeString);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format with AM/PM
    };

    return dateObject.toLocaleString("en-US", options);
  }

  const handleAddOrUpdateMentor = async (submissionId, index) => {
    Loading();

    if (selectedMentorsForEditOrAssign?.length > 0) {
      // console.log(selectedMentorsForEditOrAssign);
      const newAssign = await axios.put(
        `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/submissionId/${submissionId}/assign-mentor`,
        // `http://localhost:5000/api/v1/assignmentSubmissions/submissionId/${submissionId}/assign-mentor`,
        { mentors: selectedMentorsForEditOrAssign }
      );
      if (newAssign) {
        toast.success("Mentor Added Successfully!");
        filteredAssignments[index].mentors = selectedMentorsForEditOrAssign;
        setEditOrAssignMentor({});
      }
      // if (newAssignment?.data?.result?.acknowledged) {
      //   toast.success("Assignment added Successfully");
      //   const newNotification = await axios.post(
      //     `${process.env.REACT_APP_SOCKET_SERVER_API}/api/v1/notifications/addNotification`,
      //     {
      //       message: `New assignment added in course ${course?.courseFullName}.`,
      //       dateTime: new Date(),
      //       redirectLink: `/questLevels/${course?._id}?week=${chapter?.weekId}`,
      //       recipient: {
      //         type: "Students",
      //         organizationId: orgData?._id,
      //         courseId: course?._id,
      //         batches: selectedBatches,
      //       },
      //       type: "Create Task",
      //       readBy: [],
      //       notificationTriggeredBy: user?.email,
      //     }
      //   );
      //   console.log(newNotification);
      //   navigate(-1);
      // }

      // console.log(manageAssignment);
    }
    Loading().close();
  };

  const handleAddOrUpdateMentorToMultipleSubmission = async (mentorId) => {
    Loading();

    const mentorData = mentors?.find((item) => item?._id === mentorId);

    //console.log(mentorData);

    const sendMentorData = {
      mentorId: mentorData?._id,
      mentorEmail: mentorData?.email,
      mentorRole: mentorData?.role,
    };

    const initialMentor = {
      mentorId: selectedMentor?._id,
      mentorEmail: selectedMentor?.email,
      mentorRole: selectedMentor?.role,
    };

    // console.log(sendMentorData);

    if (sendMentorData?.mentorEmail) {
      const newAssign = await axios.put(
        `${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/assign-mentor`,
        // `http://localhost:5000/api/v1/assignmentSubmissions/assign-mentor`,
        {
          updateMentor: sendMentorData,
          submissionIds: selectedSubmissions,
          initialMentor,
        }
      );
      if (newAssign) {
        toast.success("Mentor Added Successfully!");
        setCount(count + 1);
        setSelectedSubmissions([]);
      }
      // if (newAssignment?.data?.result?.acknowledged) {
      //   toast.success("Assignment added Successfully");
      //   const newNotification = await axios.post(
      //     `${process.env.REACT_APP_SOCKET_SERVER_API}/api/v1/notifications/addNotification`,
      //     {
      //       message: `New assignment added in course ${course?.courseFullName}.`,
      //       dateTime: new Date(),
      //       redirectLink: `/questLevels/${course?._id}?week=${chapter?.weekId}`,
      //       recipient: {
      //         type: "Students",
      //         organizationId: orgData?._id,
      //         courseId: course?._id,
      //         batches: selectedBatches,
      //       },
      //       type: "Create Task",
      //       readBy: [],
      //       notificationTriggeredBy: user?.email,
      //     }
      //   );
      //   console.log(newNotification);
      //   navigate(-1);
      // }

      // console.log(manageAssignment);
    }
    Loading().close();
  };

  // console.log(filteredAssignments);

  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const availableParticipants = [
    "Participant 1",
    "Participant 2",
    "Participant 3",
    "Participant 4",
  ]; // Example list of participants
  const dropdownRef = useRef(null);

  const handleMentorSelectChange = (mentor, e) => {
    if (e.target.checked) {
      setSelectedMentorsForEditOrAssign([
        ...selectedMentorsForEditOrAssign,
        {
          mentorId: mentor?._id,
          mentorEmail: mentor?.email,
          mentorRole: mentor?.role,
        },
      ]);
    } else {
      const data = selectedMentorsForEditOrAssign?.filter(
        (item) => item?.mentorId !== mentor?._id
      );
      //  console.log(data);
      setSelectedMentorsForEditOrAssign(data);
    }
    //console.log(selectedMentorsForEditOrAssign);
    // setSelectedMentorsForEditOrAssign((prevState) =>
    //   prevState.includes(mentor)
    //     ? prevState.filter((item) => item?._id !== mentor?._id)
    //     : [...prevState, mentor]
    // );
  };


  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleEvaluate = async () => {
    Loading()
    const submissionIds =
    {
      submitterName: userInfo?.name,
      submissionIds: selectedSubmissions

    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVERLESS_API}/api/v1/assignmentSubmissions/addMultipleResult`, submissionIds);

      //  console.log(response.data.message === "Result added successfully");
      // You can also add logic to handle success, such as displaying a message or updating state
      if (response.data.message === "Result added successfully") {
        toast.success("Results added successfully!");
        Loading().close();
      }
      else {
        toast.error("Failed to add results. Please try again.");
        Loading().close();
      }

    } catch (error) {
      console.error("There was an error adding the results!", error);
      // Handle error, e.g., show an error message
      toast.error("Failed to add results. Please try again.");
      Loading().close();
    }
  };

  return (
    <div>
      <Layout>
        {/* {isLoading && (
          <div className=" flex align-items-center my-5 py-5">
            <CircularProgress className="w-full mx-auto" />
          </div>
        )} */}
        {isDataLoad === true && (
          <div className=" flex align-items-center my-5 py-5 pt-24">
            <CircularProgress className="w-full mx-auto" />
          </div>
        )}
        <div className="lg:block hidden">
          <AssignmentUpNev page={"assignment"} />
        </div>

        <div className="flex mt-20">
          <div className="w-full mx-5">
            <div className="flex justify-between">
              <div className="mt-10 text-[#F50000] text-lg font-medium">
                <p>
                  {itemDetails?.pendingEvaluations
                    ? itemDetails?.pendingEvaluations
                    : "Total Pending evaluations"}{" "}
                  - {totalPendingEvaluation}
                </p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-3 lg:gap-0 lg:space-x-4 my-4">
              <>
                {/*       <Link
                  to="/mentorAssignments"
                  onClick={() => handleTabClick("Assignments")}
                  style={{
                    fontWeight:
                      selectedTab === "Assignments" || "mentorAssignments"
                        ? "bold"
                        : "normal",
                    borderBottom:
                      selectedTab === "Assignments" || "mentorAssignments"
                        ? "2px solid black"
                        : "none",
                  }}
                >
                  Assignments
                </Link> */}
                {/*   <Link
                  to="/assignmentsQuiz"
                  onClick={() => handleTabClick("Quiz")}
                  style={{
                    fontWeight: selectedTab === "Quiz" ? "bold" : "normal",
                    borderBottom:
                      selectedTab === "Quiz" ? "2px solid black" : "none",
                  }}
                >
                  Quiz
                </Link>
                <Link
                  to="/assignmentsLiveTest"
                  onClick={() => handleTabClick("Live Test")}
                  style={{
                    fontWeight: selectedTab === "Live Test" ? "bold" : "normal",
                    borderBottom:
                      selectedTab === "Live Test" ? "2px solid black" : "none",
                  }}
                >
                  Live Test
                </Link> */}
              </>
              <select
                className="px-2 py-1 border rounded "
                value={selectedCourse?._id}
                onChange={(e) => {
                  const course = courses?.find((c) => c._id === e.target.value);
                  setSelectedCourse(course);
                }}
              >
                <option value="">
                  {itemDetails?.selectCourse
                    ? itemDetails?.selectCourse
                    : "Select Course"}
                </option>
                {courses?.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course?.courseFullName}
                  </option>
                ))}
              </select>

              <select
                className="px-2 py-1 border rounded"
                value={selectedBatch?._id}
                onChange={(e) => {
                  const batch = batchesData.find(
                    (b) => b._id === e.target.value
                  );
                  setSelectedBatch(batch);
                }}
              >
                <option value="">
                  {itemDetails?.selectBatch
                    ? itemDetails?.selectBatch
                    : "Select Batch"}
                </option>
                {batchesData?.map((batch) => (
                  <option key={batch?._id} value={batch?._id}>
                    {batch?.batchName}
                  </option>
                ))}
              </select>
              <select
                className="px-2 py-1 border rounded "
                // value={selectedBatch?._id}
                onChange={(e) => {
                  setSelectedStatus(e.currentTarget.value);
                }}
              >
                <option value="">
                  {itemDetails?.selectStatus
                    ? itemDetails?.selectStatus
                    : "Select Status"}{" "}
                </option>

                <option value="Submitted">
                  {itemDetails?.submitted
                    ? itemDetails?.submitted
                    : "Submitted"}
                </option>
                <option value="Pending">
                  {itemDetails?.pending ? itemDetails?.pending : "Pending"}
                </option>
              </select>

              {/* <button
                className="bg-sky-500 hover:bg-opacity-70 text-white px-4 py-2 rounded w-[50%]"
                onClick={applyFilters}
              >
                {itemDetails?.applyFilters
                  ? itemDetails?.applyFilters
                  : "Apply Filters"}
              </button> */}
            </div>
            {
              userInfo?.role === "execution mentor" && <div className=" flex items-center gap-5">
                <p>Show :</p>

                <select
                  className="border rounded-md px-2 py-1"
                  value={limit}
                  onChange={(e) => {
                    const newLimit = parseInt(e.target.value, 10);
                    setLimit(newLimit); // fetchAssignments will be called in useEffect when limit changes
                  }}
                >
                  <option value={10}>10 records</option>

                  <option value={25}>25 records</option>

                  <option value={50}>50 records</option>
                </select>

              </div>
            }
            {userInfo?.role === "admin" && (
              <div className="flex flex-col md:flex-row items-center gap-4">
                <div className="flex items-center gap-4">
                  <h1 className="font-medium">Filter By Mentor:</h1>
                  <select
                    className="px-2 py-1 border rounded"
                    onChange={async (e) => {
                      e.preventDefault();
                      setSelectedMentor(
                        mentors?.find((item) => item?._id === e.target.value)
                      );
                      // await applyFilters();
                      const filterAssignment = filteredAssignments?.filter(
                        (item) =>
                          item?.mentors?.some(
                            (mentor) => mentor?.mentorId === e.target.value
                          )
                      );
                      setFilteredAssignment(filterAssignment);
                    }}
                  >
                    {userInfo?.role === "admin" && (
                      <>
                        <option className="hidden" value="">
                          Select Mentor
                        </option>
                        {mentors && mentors?.map((mentor) => (
                          <option key={mentor._id} value={mentor._id}>
                            {mentor?.name}
                          </option>
                        ))}
                      </>
                    )}
                    {userInfo?.role !== "admin" && (
                      <option className="hidden">{userInfo?.name}</option>
                    )}
                  </select>
                </div>
                {selectedSubmissions?.length > 0 && (
                  <div className="flex items-center gap-5">
                    <div className="flex items-center gap-4">
                      <h1>Assign to:</h1>
                      <select
                        className="px-2 py-1 border rounded"
                        onChange={(e) => {
                          e.preventDefault();
                          handleAddOrUpdateMentorToMultipleSubmission(
                            e.target.value
                          );
                        }}
                      >
                        <option className="hidden" value="">
                          Select Mentor
                        </option>
                        {mentors?.map((mentor) => (
                          <option key={mentor._id} value={mentor._id}>
                            {mentor?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      className="bg-sky-500 hover:bg-opacity-70 text-white px-4 py-1 rounded w-[50%]"
                      onClick={handleEvaluate}>Mark as evaluate</button>
                  </div>
                )}

                <div className=" flex items-center gap-5">
                  <p>Show :</p>

                  <select
                    className="border rounded-md px-2 py-1"
                    value={limit}
                    onChange={(e) => {
                      const newLimit = parseInt(e.target.value, 10);
                      setLimit(newLimit); // fetchAssignments will be called in useEffect when limit changes
                    }}
                  >
                    <option value={10}>10 records</option>

                    <option value={25}>25 records</option>

                    <option value={50}>50 records</option>
                  </select>

                </div>
              </div>
            )}
            <>
              {/*  <div className="flex ms-10 justify-between items-center text-lg font-bold mt-10 mb-7">
                            <div className="flex items-center gap-4">
                                <p className="h-2 w-2 bg-black rounded-full"></p>
                                <p className="">

                                    Subject Name
                                </p>

                            </div>
                            <p>Total weighted marks of the Assignment</p>
                        </div>
                        <div className="ms-10">
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                            </p>
                        </div> */}

              {/*   <div className="flex justify-between items-center ms-10 mt-10 text-lg font-bold">
              <p>Grade method</p>
              <p>Name of the Lab</p>
            </div> */}

              {/*  <div className="grid grid-cols-3 gap-3 ms-5 my-10">
              {assignments?.map((assignment) => (
                <div className="bg-[#F0F7FF] rounded-[20px] flex gap-2 flex-col items-center py-5">
                  <p className="text-lg text-center font-bold">
                    {assignment?.courseName}
                  </p>
                  <p className="text-[15px] font-medium">
                    {assignment.submitter.name}
                  </p>
                  <p className="text-[15px] font-medium">
                    {assignment?.weekName}
                  </p>
                  {assignment?.submitter.result && (
                    <p className="text-[10px] text-[blue] ">Result submitted</p>
                  )}

                  <p className="text-[15px] font-bold">{assignment.taskName}</p>
                  <div className="flex gap-3 mt-5">
                    <p className="text-[15px] font-semibold text-[#038400] border-b border-b-[#038400]">
                      Accept
                    </p>
                    <p className="text-[15px] font-semibold text-[#F00] border-b border-b-[#F00]">
                      Decline
                    </p>
                  </div>
                  <Link
                    to={`/assignmentEvaluation1/${assignment?._id}`}
                    className="flex gap-2 bg-[#081765] text-[#fff] p-2 rounded-md mb-2 mt-3"
                  >
                    <img src={eye} alt="eye" />
                    <p className="text-base font-normal">View assignment</p>
                  </Link>
                </div>
              ))}
            </div> */}
            </>
            <div
              style={{
                maxWidth: `${window.innerWidth - (window.innerWidth > 1024 ? 370 : 40)
                  }px`,
              }}
              className={`h-[70vh] w-fit overflow-y-auto mt-5 border`}
            >
              <table className={` font-sans bg-white border border-gray-300`}>
                <thead className="bg-gray-800 text-white sticky top-0">
                  <tr>
                    <th className="py-3 px-6 border-b text-left whitespace-nowrap ">
                      Student Name
                      {
                        userInfo?.role==="admin" && <div>
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={
                            selectedSubmissions?.length ===
                            filteredAssignments?.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectAllStatus(true);
                              let allSubmissionId = [];
                              filteredAssignments?.forEach((item) => {
                                allSubmissionId.push(item?._id);
                              });
                              setSelectedSubmissions(allSubmissionId);
                            } else {
                              setSelectAllStatus(false);
                              setSelectedSubmissions([]);
                            }
                          }}
                        />
                        <label
                          className=" text-base font-semibold  p-2"
                          htmlFor="selectAll"
                        >
                          Select All
                        </label>
                      </div>
                      }
                      
                    </th>
                    <th className="py-3 px-6 border-b text-left whitespace-nowrap">
                      Assignment Name
                    </th>
                    <th className="py-3 px-6 border-b text-left whitespace-nowrap">
                      Submission Date
                    </th>
                    <th className="py-3 px-6 border-b text-center whitespace-nowrap">
                      Submission Status
                    </th>
                    <th className="py-3 px-6 border-b text-left whitespace-nowrap">
                      View Assignment
                    </th>
                    <th className="py-3 px-6 border-b text-left whitespace-nowrap">
                      Assigned Mentor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssignments?.map((assignment, index) => {
                    return (
                      <tr
                        key={assignment?._id}
                        className={
                          index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"
                        }
                      >
                        <td className=" px-6 border-b text-left whitespace-nowrap">
                          {userInfo?.role === "admin" && (
                            <input
                              className="mr-2"
                              type="checkbox"
                              id={`assignment-${assignment?._id}`}
                              name={`assignment-${assignment?._id}`}
                              value={`${assignment?._id}`}
                              checked={selectedSubmissions?.includes(
                                assignment?._id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectAllStatus(true);
                                  setSelectedSubmissions([
                                    ...selectedSubmissions,
                                    assignment?._id,
                                  ]);
                                } else {
                                  setSelectAllStatus(false);
                                  let allSubmissionId =
                                    selectedSubmissions?.filter(
                                      (item) => item !== assignment?._id
                                    );
                                  setSelectedSubmissions(allSubmissionId);
                                }
                              }}
                            />
                          )}
                          {assignment?.submitter?.name}
                        </td>
                        <td className=" px-6 border-b text-left">
                          {assignment?.taskName}
                        </td>
                        <td className=" px-6 border-b text-left">
                          {formatDateTime(assignment?.submissionDateTime)}
                        </td>

                        <td className=" px-6 border-b text-left">
                          {assignment?.submitter?.result ? (
                            <span className="text-green font-semibold">
                              &#x2713;{" "}
                              {itemDetails?.evaluated
                                ? itemDetails?.evaluated
                                : "Evaluated"}
                            </span>
                          ) : (
                            <span className="text-red-600 font-semibold">
                              &#x2717;{" "}
                              {itemDetails?.pending
                                ? itemDetails?.pending
                                : "Pending"}
                            </span>
                          )}
                        </td>
                        <td className=" px-6 border-b text-left">
                          <Link
                            to={`/assignmentEvaluation2/${assignment?._id}`}
                            className="flex gap-2 bg-[#081765] hover:bg-opacity-70 text-[#fff] px-2 py-1 rounded-md mb-2 mt-3"
                          >
                            <img src={eye} alt="eye" />
                            <p className="text-base font-normal">
                              {itemDetails?.view ? itemDetails?.view : "View"}
                            </p>
                          </Link>
                        </td>
                        <td className=" px-6 border-b text-left">
                          {/* <select
                            className="p-2 border rounded"
                            defaultValue={
                              mentors?.find(
                                (item) =>
                                  item?._id === assignment?.mentor?.mentorId
                              )?._id
                            }
                            onChange={(e) => {
                              e.preventDefault();
                              handleAddOrUpdateMentor(
                                assignment?._id,
                                e.target.value
                              );
                            }}
                          >
                            {userInfo?.role === "admin" && (
                              <>
                                <option className="hidden" value="">
                                  {mentors?.find(
                                    (item) =>
                                      item?._id === assignment?.mentor?.mentorId
                                  )?.name || "Select Mentor"}
                                </option>
                                {mentors?.map((mentor) => (
                                  <option
                                    className={`${
                                      mentors?.find(
                                        (item) =>
                                          item?._id ===
                                          assignment?.mentor?.mentorId
                                      )?._id === mentor?._id && "hidden"
                                    }`}
                                    key={mentor._id}
                                    value={mentor._id}
                                  >
                                    {mentor?.name}
                                  </option>
                                ))}
                              </>
                            )}
                            {userInfo?.role !== "admin" && (
                              <option className="hidden">
                                {userInfo?.name}
                              </option>
                            )}
                          </select> */}

                          <div className=" flex gap-1">
                            <div>
                              {editOrAssignMentor?._id !== assignment?._id && (
                                <div className="flex gap-1">
                                  <div
                                    className="bg-[#F6F7FF] border-[1px] border-[#CECECE] w-[170px] rounded-[6px] px-2 py-1 cursor-pointer overflow-x-auto"
                                    onClick={handleToggleDropdown}
                                  >
                                    {assignment?.mentors?.length > 0 ? (
                                      assignment?.mentors?.map((mentor, idx) => (
                                        <span key={idx} className="whitespace-nowrap">
                                          {assignment?.mentors?.length > idx + 1
                                            ? `${mentors?.find(
                                              (item) => item?.email === mentor?.mentorEmail
                                            )?.name}, `
                                            : mentors?.find(
                                              (item) => item?.email === mentor?.mentorEmail
                                            )?.name}
                                        </span>
                                      ))
                                    ) : (
                                      <span className="whitespace-nowrap">Mentor not assigned!</span>
                                    )}
                                  </div>

                                  {userInfo?.role === "admin" && (
                                    <button
                                      onClick={() => {
                                        setEditOrAssignMentor(assignment);
                                        setSelectedMentorsForEditOrAssign(
                                          assignment?.mentors
                                            ? assignment?.mentors
                                            : []
                                        );
                                      }}
                                      className="px-3 py-1 bg-blue text-white rounded"
                                    >
                                      Edit
                                    </button>
                                  )}
                                </div>
                              )}
                              {editOrAssignMentor?._id === assignment?._id &&
                                userInfo?.role === "admin" && (
                                  <div className="flex gap-1">
                                    <div className=" w-full rounded-md shadow-lg bg-white">
                                      <ul className="max-h-48 overflow-auto rounded-md py-1 text-base leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5 flex">
                                        {mentors?.map((mentor, idx) => (
                                          <li
                                            key={mentor?._id + idx}
                                            className="flex items-center p-2"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={selectedMentorsForEditOrAssign?.find(
                                                (item) =>
                                                  item?.mentorId === mentor?._id
                                              )}
                                              onChange={(e) =>
                                                handleMentorSelectChange(
                                                  mentor,
                                                  e
                                                )
                                              }
                                              className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                                            />
                                            <span className="ml-2 whitespace-nowrap text-gray-700">
                                              {mentor?.name}
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <button
                                      onClick={() =>
                                        handleAddOrUpdateMentor(
                                          assignment?._id,
                                          index
                                        )
                                      }
                                      className="px-3 py-1 bg-blue text-white rounded"
                                    >
                                      Save
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                          {selectedParticipants.length > 0 && (
                            <>
                              <h1 className="text-[18px] px-2 font-[700] mt-[16px] mb-[4px]">
                                Selected Participants
                              </h1>
                              <div className="tag-container my-2 flex flex-wrap w-full rounded-lg border-2 p-2 mx-2">
                                {selectedParticipants.map(
                                  (participant, index) => (
                                    <div
                                      key={index}
                                      className="m-1 h-fit rounded-lg border-2 py-1 px-2"
                                    >
                                      {participant}
                                      <span
                                        className="cursor-pointer pl-1 text-xl font-bold"
                                        onClick={() =>
                                          handleMentorSelectChange(participant)
                                        }
                                      >
                                        ×
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {
                filteredAssignments.length === 0 && <p className="text-center py-10 text-[red] font-semibold text-lg">No data exist</p>
              }
            </div>
            {/* Pagination UI */}
            <div className="flex justify-center items-center space-x-2 mt-6">
              <button
                className={`px-3 py-2 border rounded-md ${currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                  }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              {getPagination().map((page, index) =>
                typeof page === "string" ? (
                  <span key={index} className="px-3 py-2">
                    {page}
                  </span>
                ) : (
                  <button
                    key={page}
                    className={`px-3 py-2 border rounded-md ${page === currentPage
                      ? "bg-blue text-white"
                      : "bg-white text-black hover:bg-gray-100"
                      }`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                )
              )}
              <button
                className={`px-3 py-2 border rounded-md ${currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
                  }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </div>

          {/*  <div>
            <AssignmentRightNev />
          </div> */}
        </div>
      </Layout>
    </div>
  );
};

export default MentorAssignments;
